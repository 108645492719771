import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import Carousel from 'react-material-ui-carousel';
import { useParams, useLocation } from 'react-router-dom';

import ProductHero from './PropertyHero';
import MapTextLeft from './MapTextLeft';
import ImageCarousel from './Carousel';

function PropertyDetail() {
    //const location = useLocation();
    //const { property } = location.state || {};

    // State to store property details and photos
    //const [photoUrls, setPhotoUrls] = useState([]);
    //const [frontUrl, setFrontUrl] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    return (
        <div>
            <ProductHero />
            <MapTextLeft />
            <ImageCarousel />
        </div>
    );
}

export default PropertyDetail;
