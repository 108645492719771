import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, LoadScript, useLoadScript } from "@react-google-maps/api";
import { MarkerF } from '@react-google-maps/api'
import Skeleton from '@mui/material/Skeleton';

import axios from 'axios';
import config from '../config.js';
const environment = process.env.NODE_ENV || 'development';
const api = axios.create({
  baseURL: config[environment].apiUrl.replace('/api', ''),
});

function PropertyMap({ property }) {
  const [location, setLocation] = useState(null);
  const [mapKey, setMapKey] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLocation(); 
  }, [property.address]);

  const fetchLocation = async () => {
    try {
      // Fetch the Google Maps API key from your backend
      const apiKeyResponse = await api.get('/api/getGoogleMapKey');
      const apiKey = apiKeyResponse.data.apiKey;
      setMapKey(apiKey);

      if (!apiKey) {
        throw new Error('API key is missing.');
      }

      const fullAddr = `${property.address}, ${property.city}, ${property.state} ${property.zip}`;
      // Construct the Geocoding API URL
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        fullAddr
      )}&key=${apiKey}`;

      // Fetch location data
      const locationResponse = await fetch(apiUrl);
      const locationData = await locationResponse.json();
      if (locationData.results && locationData.results.length > 0) {
        const result = locationData.results[0].geometry.location;
        setLocation(result); // Update location state
      } else {
        setError('No location data found');
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
      setError('Failed to fetch location data.');
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  const mapContainerStyle = {
    width: '100%',
    height: '75vh',
  };

  // Render the map or an error message
  return (
    <div>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height="70vh" />
      ) : (
        <LoadScript async googleMapsApiKey={mapKey}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{ lat: location.lat, lng: location.lng }}
            zoom={17}
          >
            <MarkerF position={{ lat: location.lat, lng: location.lng }} />
          </GoogleMap>
        </LoadScript>
      )}
    </div>

  );
}

export default PropertyMap;
