import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { 
    Typography,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Set the base URL for Axios requests
import axios from 'axios';
import config from '../config.js';
const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
const api = axios.create({
  baseURL:  config[environment].apiUrl.replace('/api', ''),
});

function PropertyCard({ property }) {
    const navigate = useNavigate();
    //const [saleMethod, SetSaleMethod] = useState(property.salemethod)

    const [photoUrl, setPhotoUrl] = useState([]);

    useEffect(() => {
        const getPhotoUrl = async () => {
            const response = await api.post('/api/getFrontPhoto', {
                address: property.address,
            });
            setPhotoUrl(response.data.photoUrl);
        }
        getPhotoUrl()
    }, [property.address]);

    const handleCardClick = () => {
        // Navigate to the PropertyDetail page and pass the property object as state
        navigate(`/properties/${property.address.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "").toLowerCase()}`  , { state: { property } });
    };

    return (
        <Card sx={{ maxWidth: 600, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',}} >
        <CardActionArea onClick={handleCardClick}>
            <CardMedia
            component="img"
            height="300"
            image={photoUrl}
            />
            <Box //Black bar
            sx={{
                background: 'black',
                color: 'white',
                textAlign: 'center',
                padding: '15px 0', 
            }}
            >
                {property.salemethod && (
                <Typography variant="h5" component="div">
                    {property.salemethod.charAt(0).toUpperCase() + property.salemethod.slice(1)}
                </Typography>
                )}
            </Box>
            <CardContent>

            {property.address && (
            <Typography align="center" sx={{fontSize: '2rem'}}>
                {property.address}
            </Typography>
            )} 

            {property.city && property.state && property.zip && (
            <Typography gutterBottom variant="body1" color="text.secondary" align="center" sx={{mb: 1}}>
                {property.city}, {property.state} {property.zip}
            </Typography>
            )}

            {property.salemethod && property.salemethod.toLowerCase() === "rental" &&
                <Typography align="center" sx={{fontSize: '1.75rem'}}>
                    ${property.rentprice.toLocaleString()}.00/month
                </Typography>
            }

            {property.salemethod && property.salemethod.toLowerCase() === "wholesale" &&
                <Typography align="center" sx={{fontSize: '1.75rem'}}>
                ${property.price.toLocaleString()}.00
                </Typography>
            }

            {property.salemethod && property.salemethod.toLowerCase() === "turnkey" && 
                <Typography align="center" sx={{fontSize: '1.75rem'}}>
                    ${property.price.toLocaleString()}.00
                </Typography>
            }         


            <Typography variant="body1" color="text.secondary" align="center">
                Details: {property.bedrooms} Bed | {property.bathrooms} Bath
            </Typography>
            </CardContent>
        </CardActionArea>
        </Card>
    );
}

export default PropertyCard;
