import { useState } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import { 
  Snackbar,
  Alert,
} from '@mui/material';

import { alpha, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';

import { useRouter } from '../../routes/hooks';

import { bgGradient } from '../../theme/css';

import Logo from '../../components/logo';
import Iconify from '../../components/iconify';

import axios from 'axios';
import config from '../../../config.js';
const environment = process.env.NODE_ENV || 'development';
const api = axios.create({
  baseURL: config[environment].apiUrl.replace('/api', ''),
});

// ----------------------------------------------------------------------

export default function LoginView() {
  //const auth = getAuth(firebaseApp);
  //console.log("Auth Login:", auth);

  const theme = useTheme();

  const router = useRouter();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(''); // State for email input
  const [password, setPassword] = useState(''); // State for password input
  const [emailError, setEmailError] = useState(false); // Track email error
  const [emailErrorText, setEmailErrorText] = useState(""); 
  const [passwordError, setPasswordError] = useState(false); // Track password error
  const [passwordErrorText, setPasswordErrorText] = useState("");  
  const [alertOn, setAlertOn] = useState(false);

  const handleClick = async () => {
    setEmailError(false); // Reset email error state
    setPasswordError(false); // Reset password error state

    if (!email) {
      setEmailError(true); // Set email error state
      setEmailErrorText("Email Required");
      return;
    }

    if (!password) {
      setPasswordError(true); // Set password error state
      setPasswordErrorText("Password Required");
      return;
    }

    try {
      // Sign in with email and password using Firebase
      const response = await api.post('/api/firebaseSignin', { email, password });
      //console.log("Singin Response:", response.data);
      sessionStorage.setItem('uid', response.data.user.uid);
      sessionStorage.setItem('email', response.data.user.email);
      
      // Redirect to the dashboard or admin page on successful sign-in
      router.push('/admin/add-property');
    } catch (error) {
      // Handle sign-in error
      setEmailError(true);
      setEmailErrorText("");
      setPasswordError(true);
      setPasswordErrorText("");
      setAlertOn(true);
      //console.error('Sign-in error:', error);
    }
  };

  const alertClose = () => {
    setAlertOn(false);
  }

  const renderForm = (
    <>
      <Stack spacing={3}>
        <TextField 
          name="email" 
          label="Email address" 
          onChange={(e) => setEmail(e.target.value)}
          error={emailError} // Set error state
          helperText={emailError && emailErrorText} // Display error message
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
          error={passwordError} // Set error state
          helperText={passwordError && passwordErrorText} // Display error message
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="success"
        onClick={handleClick}
        sx={{mt: 2}}
      >
        Login
      </LoadingButton>
    </>
  );

  return (
    <Container maxWidth="md" backgroundColor="#f9f9f9" sx={{mb: 10, mt: 20}}>
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 600,
            //mt: 20,
            //mb: 20,
          }}
        >
          <Typography variant="h4" align="center" sx={{mb:2}}>Sign in to Admin Console</Typography>

          {renderForm}
          
        </Card>
        
      </Stack>

      <Snackbar
          open={alertOn}
          autoHideDuration={4000}
          onClose={alertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='error'
            variant='filled'
            
          >
            Email or Password Wrong
          </Alert>
        </Snackbar>
      
    </Box>
    </Container>
  );
}
