import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import Carousel from 'react-material-ui-carousel';
import { useParams, useLocation } from 'react-router-dom';

import ProductHero from './PropertyHero';
import MapTextLeft from './MapTextLeft';
import ImageCarousel from './Carousel';
import CenterText from './CenterText';

// Set the base URL for Axios requests
import axios from 'axios';
import config from '../config.js';
const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
const api = axios.create({
  baseURL:  config[environment].apiUrl.replace('/api', ''),
});

function PropertyDetail() {
    const location = useLocation();
    const { property } = location.state || {};

    // State to store property details and photos
    const [photoUrls, setPhotoUrls] = useState([]);
    const [frontUrl, setFrontUrl] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (property) {
            const getPhotoUrls = async () => {
                const response = await api.post('/api/getAllPhotos', {
                    address: property.address,
                });
                setFrontUrl(response.data.frontPhoto);
                setPhotoUrls(response.data.otherPhotos);
            }
            getPhotoUrls();
        }
    }, [property]);

    return (
        <div>
            {photoUrls ? (
                <div>
                    <ProductHero imgUrl={frontUrl} property={property}/>
                    <MapTextLeft property={property}/>
                    {property.salemethod.toLowerCase() === 'rent' && <CenterText />}
                    <ImageCarousel imageUrls={photoUrls}/>
                </div>
            ) : (
                <p>Loading property details...</p>
            )}
        </div>
    );
}

export default PropertyDetail;
