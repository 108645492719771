//import { Helmet } from 'react-helmet-async';

import { UpdatePropertyView } from '../sections/updateproperty/view';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Suspense, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function UpdatePropertyPage() {
  const navigate = useNavigate(); // Use useNavigate for redirection

  useEffect(() => {
    const getCurrentUser = async () => {
      const uid = sessionStorage.getItem('uid');
      const email = sessionStorage.getItem('email');
      if (!uid || !email) {
        navigate('/admin/login');
        return;
      }
    }
    getCurrentUser();
  }, [navigate]);

  return (
    <>

      <DashboardLayout>
          <Suspense>
            <Outlet />
            <UpdatePropertyView/>
          </Suspense>
      </DashboardLayout>
      

    </>
  );
}
