import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import AWS from 'aws-sdk';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  MenuItem,
  Popover,
  Box,
  Alert,
  Snackbar,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@mui/material';

import LoanFormat from './FormFormat.js';

import axios from 'axios';
import config from '../config.js';

const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
// Set the base URL for Axios requests
const api = axios.create({
  baseURL:  config[environment].apiUrl.replace('/api', ''),
});

function LoanFormPage() {
  const [formData, setFormData] = useState({
    main_firstname: '',
    main_lastname: '',
    main_email: '',
    main_phone: '',
    main_title: '',
    main_borrower: '',

    b1_ind_name: '',
    b1_ind_address: '',
    b1_ind_email: '',
    b1_ind_phone: '',
    b1_ind_dob: '',
    b1_ind_ssn: '',
    b1_ind_credit: '',
    b1_ind_income: '',
    b1_ind_reserves: '',
    b1_ind_employer_name: '',
    b1_ind_employer_address: '',
    b1_ind_employer_phone: '',
    b1_ind_dec_1: '',
    b1_ind_dec_2: '',
    b1_ind_dec_3: '',
    b1_ind_dec_4: '',
    b1_ind_dec_5: '',
    b1_ind_dec_6: '',
    b1_ind_dec_desc: '',

    ind_co_borrower: '',

    b2_ind_name: '',
    b2_ind_address: '',
    b2_ind_email: '',
    b2_ind_phone: '',
    b2_ind_dob: '',
    b2_ind_ssn: '',
    b2_ind_credit: '',
    b2_ind_income: '',
    b2_ind_reserves: '',
    b2_ind_employer_name: '',
    b2_ind_employer_address: '',
    b2_ind_employer_phone: '',
    b2_ind_dec_1: '',
    b2_ind_dec_2: '',
    b2_ind_dec_3: '',
    b2_ind_dec_4: '',
    b2_ind_dec_5: '',
    b2_ind_dec_6: '',
    b2_ind_dec_desc: '',

    ent_name: '',
    ent_address: '',
    ent_tax_id: '',
    ent_state_form: '',
    
    b1_ent_name: '',
    b1_ent_email: '',
    b1_ent_phone: '',
    b1_ent_address: '',
    b1_ent_dob: '',
    b1_ent_ssn: '',

    ent_co_borrower: '',

    b2_ent_name: '',
    b2_ent_email: '',
    b2_ent_phone: '',
    b2_ent_address: '',
    b2_ent_dob: '',
    b2_ent_ssn: '',
    b2_ent_percent: '',

    ent_co_borrower2: '',

    other_ent_desc: '',

    ent_owner_credit: '',
    ent_owner_reserves: '',
    ent_owner_gross: '',
    ent_owner_net: '',
    ent_dec_1: '',
    ent_dec_2: '',
    ent_dec_3: '',
    ent_dec_4: '',
    ent_dec_5: '',
    ent_dec_6: '',
    ent_dec_desc: '',

    loan_req_amount: '',
    loan_req_length: '',
    loan_req_close: '',
    loan_req_finance: '',

    loan_exist_balance: '',
    loan_exist_status: '',
    loan_exist_desc: '',

    use_of_funds: '',

    purchase_price: '',

    collat_addr: '',
    collat_value: '',
    collat_use: '',
    collat_desc: '',

    construct: '',
    construct_price: '',
    construct_as_value: '',
    construct_after_value: '',

    exit_strat: '',

    other_prop: '',
    other_prop_desc: '',
    similar_proj: '',

    additional_desc: '',
    legal_dec_1: '',
    legal_dec_2: '',
    legal_dec_3: '',
    legal_dec_4: '',

  });
  const [formErrors, setFormErrors] = useState({});
  const [anchorSubmit, setAnchorSubmit] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alertErrorOn, setAlertErrorOn] = useState(false);
  const [selectedCollatUse, setSelectedCollatUse] = useState([]);

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    //console.log("Change:", name, value);
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
    
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    //console.log("vals:", name, value, checked);
  
    let updatedCollatUse = [];
    if (checked) {
      // Add the selected value to the array
      updatedCollatUse = [...selectedCollatUse, value];
    } else {
      // Remove the deselected value from the array
      updatedCollatUse = selectedCollatUse.filter((item) => item !== value);
    }
  
    setSelectedCollatUse(updatedCollatUse);
  
    const selectedValuesString = updatedCollatUse.join(', '); // Convert the selected values to a comma-separated string
    //console.log("Selected:", selectedValuesString);
  
    setFormData({ ...formData, [name]: selectedValuesString });
    setFormErrors({ ...formErrors, [name]: '' });
    //console.log("Fordata:", formData);
  };

  const handleSingleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    //console.log("vals:", name, value, checked);
  
    // Update the formData based on the checkbox state
    setFormData({ ...formData, [name]: checked ? "Yes" : 'No' });
    setFormErrors({ ...formErrors, [name]: '' });
    //console.log("Formdata:", formData);
  };

  const confirmSubmit = (e) => {
    e.preventDefault();
    setAnchorSubmit(e.currentTarget);
    setOpenPopover(true);
  }

  const handleClose = () => {
    setAnchorSubmit(null);
    setOpenPopover(false);
  }

  const alertClose = () => {
    setAlertOn(false);
    setAlertErrorOn(false);
  }


  const handleSubmit = async (e) => {
    console.log("Form Submitting");
    e.preventDefault();
    setOpenPopover(false);
    setAnchorSubmit(null);

    // Validate the form fields
    const errors = {};
    for (const key in formData) {
      /*if (formData[key] === '' && key !== 'message') {
        errors[key] = 'This field is required';
      }*/
      if(formData.main_firstname === '' && key === "main_firstname"){
        errors[key] = 'This field is required';
      } else if (formData.main_lastname === '' && key === "main_lastname") {
        errors[key] = 'This field is required';
      } else if (formData.main_email === '' && key === "main_email") {
        errors[key] = 'This field is required';
      } else if (formData.main_phone === '' && key === "main_phone") {
        errors[key] = 'This field is required';
      }
    }
    
    // If there are validation errors, update the state and prevent submission
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      console.log("Errors:", errors);
      setAlertErrorOn(true);
      return;
    }   

    //AWS Submission
    const currentDate = new Date(); // Get the current date and time
    const day = currentDate.getDate(); // Get the day (1-31)
    const month = currentDate.getMonth() + 1; // Get the month (0-11, so we add 1 to make it 1-12)
    const year = currentDate.getFullYear(); // Get the full year (e.g., 2023)    
    const bucketName = 'skyymyles-loan-applications';
    const key = `${month}-${year}/${formData.main_firstname}-${formData.main_lastname}-${month}-${day}-${year}.json`; 
    const data_formatted = ReactDOMServer.renderToString(<LoanFormat formData={formData} />);
    
    try {
      const response = await api.post('/api/putS3Bucket', {
        Bucket: bucketName,
        Key: key,
        Body: data_formatted,
        ContentType: 'text/html',
      });
    } catch (error) {
      console.error('testchecking email:', error.response ? error.response.data : error.message);
    }

    try {
      const response = await api.post('/api/emailLoanForm', {
        Body: data_formatted,
        ContentType: 'text/html',
      });      
    } catch (error) {
      console.error('testchecking email:', error.response ? error.response.data : error.message);
    }
    setAlertOn(true);
   
  };

  return (
    <Container maxWidth="md" backgroundColor="#f9f9f9" sx={{mt: 15, mb: 5}}>
      <form >

        <Typography  fullWidth variant="h5" align="center" >
          <span style={{ textDecoration: 'underline' }}>
          All Loans Are NO DOC or LIMITED DOC
          </span>
        </Typography>
        <Typography  fullWidth variant="h5" align="center" >
          <span style={{ textDecoration: 'underline' }}>
          There is NO Minimum Credit Score
          </span>
        </Typography>
        <Typography  fullWidth variant="h5" align="center" >
          <span style={{ textDecoration: 'underline' }}>
          We DO Offer Construction Loans
          </span>
        </Typography>
        <Typography  fullWidth variant="h5" align="center" >
          <span style={{ textDecoration: 'underline' }}>
          We CANNOT Lend on Primary Residences - Investment Properties Only Please!
          </span>
        </Typography>

        <Typography variant="h2" align="center" sx={{mt: 2}}>
          Loan Application
        </Typography>
        <Typography  fullWidth variant="h5" align="center" >
            Please fill out the following information to apply for a hard money loan.
        </Typography>
        <Typography  fullWidth variant="body2" align="center" gutterBottom sx={{mt: 1, mb: 2}}>
            Note: Required *
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="First Name"
                name="main_firstname"
                fullWidth
                variant="outlined"
                value={formData.main_firstname}
                onChange={handleInputChange}
                error={!!formErrors.main_firstname}
                helpertext={formErrors.main_firstname || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Last Name"
                name="main_lastname"
                fullWidth
                variant="outlined"
                value={formData.main_lastname}
                onChange={handleInputChange}
                error={!!formErrors.main_lastname}
                helpertext={formErrors.main_lastname || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Email"
                name="main_email"
                fullWidth
                variant="outlined"
                value={formData.main_email}
                onChange={handleInputChange}
                error={!!formErrors.main_email}
                helperText={formErrors.main_email || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Phone Number"
                name="main_phone"
                fullWidth
                variant="outlined"
                value={formData.main_phone}
                onChange={handleInputChange}
                error={!!formErrors.main_phone}
                helperText={formErrors.main_phone || ""}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
            required
            select
            label="Your Title/Role"
            fullWidth
            name="main_title"
            value={formData.main_title}
            onChange={handleInputChange}
            error={!!formErrors.main_title}
            helperText={formErrors.main_title || ""}
            >
                <MenuItem key="Principal / Borrower" value="Principal / Borrower"> Principal / Borrower </MenuItem>
                <MenuItem key="Broker" value="Broker">Broker</MenuItem>
                <MenuItem key="Attorney for Principal / Borrower" value="Attorney for Principal / Borrower">Attorney for Principal / Borrower</MenuItem>
                <MenuItem key="Accountant/CFO for Principal / Borrower" value="Accountant/CFO for Principal / Borrower">Accountant/CFO for Principal / Borrower</MenuItem>
                <MenuItem key="Other" value="Other">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
            required
            select
            label="Borrower Is?"
            fullWidth
            name="main_borrower"
            value={formData.main_borrower}
            onChange={handleInputChange}
            error={!!formErrors.main_borrower}
            helperText={formErrors.main_borrower || ""}
            >
                <MenuItem key="Individual" value="Individual"> Individual </MenuItem>
                <MenuItem key="Entity" value="Entity">Entity</MenuItem>
            </TextField>
          </Grid>
        </Grid>
             
          

        { formData.main_borrower === "Individual" && (
        <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Individual Borrower #1 Info
          </Divider>
          <Grid item xs={12}>
            <TextField
                required
                label="Borrower Full Name"
                name="b1_ind_name"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_name}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_name}
                helperText={formErrors.b1_ind_name || ""}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Email"
                name="b1_ind_email"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_email}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_email}
                helperText={formErrors.b1_ind_email || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Phone Number"
                name="b1_ind_phone"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_phone}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_phone}
                helperText={formErrors.b1_ind_phone || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Social Security Number (SSN)"
                name="b1_ind_ssn"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_ssn}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_ssn}
                helperText={formErrors.b1_ind_ssn || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Date of Birth"
                name="b1_ind_dob"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_dob}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_dob}
                helperText={formErrors.b1_ind_dob || ""}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
                required
                label="Address"
                name="b1_ind_address"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_address}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_address}
                helperText={formErrors.b1_ind_address || "Enter full address including street, city, state, zip"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
            required
            select
            label="Credit"
            fullWidth
            name="b1_ind_credit"
            value={formData.b1_ind_credit}
            onChange={handleInputChange}
            error={!!formErrors.b1_ind_credit}
            helperText={formErrors.b1_ind_credit || ""}
            >
                <MenuItem key="Poor" value="Poor 300-579">Poor 300-579 </MenuItem>
                <MenuItem key="Fair" value="Fair 580-669">Fair 580-669</MenuItem>
                <MenuItem key="Good" value="Good 670-739">Good 670-739</MenuItem>
                <MenuItem key="Very Good" value="Very Good 740-799">Very Good 740-799</MenuItem>
                <MenuItem key="Excellent" value="Excellent 800-850">Excellent 800-850</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
                required
                label="Prior Tax Year Income"
                name="b1_ind_income"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_income}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_income}
                helperText={formErrors.b1_ind_income || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
                required
                label="Total Cash Reserves"
                name="b1_ind_reserves"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_reserves}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_reserves}
                helperText={formErrors.b1_ind_reserves || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Employer Name"
                name="b1_ind_employer_name"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_employer_name}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_employer_name}
                helperText={formErrors.b1_ind_employer_name || ""}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Employer Phone Number"
                name="b1_ind_employer_phone"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_employer_phone}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_employer_phone}
                helperText={formErrors.b1_ind_employer_phone || ""}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
                required
                label="Employer Address"
                name="b1_ind_employer_address"
                fullWidth
                variant="outlined"
                value={formData.b1_ind_employer_address}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_employer_address}
                helperText={formErrors.b1_ind_employer_address || ""}
            />
          </Grid>

          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Borrower #1 Financial Declarations
          </Divider>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are there any outstanding judgments against you?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b1_ind_dec_1"
                  value={formData.b1_ind_dec_1}
                  error={!!formErrors.b1_ind_dec_1}
                  helperText={formErrors.b1_ind_dec_1 || ""}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are you in or have you been declared bankrupt in the past 7 years?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b1_ind_dec_2"
                  value={formData.b1_ind_dec_2}
                  error={!!formErrors.b1_ind_dec_2}
                  helperText={formErrors.b1_ind_dec_2 || ""}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are you a party to any current lawsuits?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b1_ind_dec_3"
                  value={formData.b1_ind_dec_3}
                  error={!!formErrors.b1_ind_dec_3}
                  helperText={formErrors.b1_ind_dec_3 || ""}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Do you have any unpaid federal or state taxes?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b1_ind_dec_4"
                  value={formData.b1_ind_dec_4}
                  error={!!formErrors.b1_ind_dec_4}
                  helperText={formErrors.b1_ind_dec_4 || ""}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Have you ever had any property you own foreclosed against?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b1_ind_dec_5"
                  value={formData.b1_ind_dec_5}
                  error={!!formErrors.b1_ind_dec_5}
                  helperText={formErrors.b1_ind_dec_5 || ""}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Have you ever defaulted on a loan?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b1_ind_dec_6"
                  value={formData.b1_ind_dec_6}
                  error={!!formErrors.b1_ind_dec_6}
                  helperText={formErrors.b1_ind_dec_6 || ""}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
                required
                label="Please Explain Any Yes Answers"
                name="b1_ind_dec_desc"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.b1_ind_dec_desc}
                onChange={handleInputChange}
                error={!!formErrors.b1_ind_dec_desc}
                helperText={formErrors.b1_ind_dec_desc || "If any answers above are yes, please example in detail"}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
            required
            select
            label="Will There Be a Co-Borrower?"
            fullWidth
            name="ind_co_borrower"
            value={formData.ind_co_borrower}
            onChange={handleInputChange}
            error={!!formErrors.ind_co_borrower}
            helperText={formErrors.ind_co_borrower || ""}
            >
                <MenuItem key="Yes" value="Yes"> Yes </MenuItem>
                <MenuItem key="No" value="No">No</MenuItem>
            </TextField>
          </Grid>
        
        </Grid>
        )}




      { formData.main_borrower === "Individual" && formData.ind_co_borrower === "Yes" && (
        <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Borrower #2 Info
          </Divider>
          <Grid item xs={12} md={12}>
            <TextField
                required
                label="Borrower Full Name"
                name="b2_ind_name"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_name}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_name}
                helperText={formErrors.b2_ind_name || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Email"
                name="b2_ind_email"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_email}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_email}
                helperText={formErrors.b2_ind_email || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Phone Number"
                name="b2_ind_phone"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_phone}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_phone}
                helperText={formErrors.b2_ind_phone || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Social Security Number (SSN)"
                name="b2_ind_ssn"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_ssn}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_ssn}
                helperText={formErrors.b2_ind_ssn || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Date of Birth"
                name="b2_ind_dob"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_dob}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_dob}
                helperText={formErrors.b2_ind_dob || ""}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
                required
                label="Address"
                name="b2_ind_address"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_address}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_address}
                helperText={formErrors.b2_ind_address || "Enter full address including street, city, state, zip"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
            required
            select
            label="Credit"
            fullWidth
            name="b2_ind_credit"
            value={formData.b2_ind_credit}
            onChange={handleInputChange}
            error={!!formErrors.b2_ind_credit}
            helperText={formErrors.b2_ind_credit || ""}
            >
                <MenuItem key="Poor" value="Poor 300-579">Poor 300-579 </MenuItem>
                <MenuItem key="Fair" value="Fair 580-669">Fair 580-669</MenuItem>
                <MenuItem key="Good" value="Good 670-739">Good 670-739</MenuItem>
                <MenuItem key="Very Good" value="Very Good 740-799">Very Good 740-799</MenuItem>
                <MenuItem key="Excellent" value="Excellent 800-850">Excellent 800-850</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
                required
                label="Prior Tax Year Income"
                name="b2_ind_income"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_income}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_income}
                helperText={formErrors.b2_ind_income || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
                required
                label="Total Cash Reserves"
                name="b2_ind_reserves"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_reserves}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_reserves}
                helperText={formErrors.b2_ind_reserves || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Employer Name"
                name="b2_ind_employer_name"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_employer_name}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_employer_name}
                helperText={formErrors.b2_ind_employer_name || ""}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Employer Phone Number"
                name="b2_ind_employer_phone"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_employer_phone}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_employer_phone}
                helperText={formErrors.b2_ind_employer_phone || ""}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
                required
                label="Employer Address"
                name="b2_ind_employer_address"
                fullWidth
                variant="outlined"
                value={formData.b2_ind_employer_address}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_employer_address}
                helperText={formErrors.b2_ind_employer_address || ""}
            />
          </Grid>

          

          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Borrower #2 Financial Declarations
          </Divider>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are there any outstanding judgments against you?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b2_ind_dec_1"
                  value={formData.b2_ind_dec_1}
                  onChange={handleInputChange}
                  error={!!formErrors.b2_ind_dec_1}
                  helperText={formErrors.b2_ind_dec_1 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are you in or have you been declared bankrupt in the past 7 years?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b2_ind_dec_2"
                  value={formData.b2_ind_dec_2}
                  onChange={handleInputChange}
                  error={!!formErrors.b2_ind_dec_2}
                  helperText={formErrors.b2_ind_dec_2 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are you a party to any current lawsuits?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b2_ind_dec_3"
                  value={formData.b2_ind_dec_3}
                  onChange={handleInputChange}
                  error={!!formErrors.b2_ind_dec_3}
                  helperText={formErrors.b2_ind_dec_3 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Do you have any unpaid federal or state taxes?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b2_ind_dec_4"
                  value={formData.b2_ind_dec_4}
                  onChange={handleInputChange}
                  error={!!formErrors.b2_ind_dec_4}
                  helperText={formErrors.b2_ind_dec_4 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Have you ever had any property you own foreclosed against?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="b2_ind_dec_5"
                  value={formData.b2_ind_dec_5}
                  onChange={handleInputChange}
                  error={!!formErrors.b2_ind_dec_5}
                  helperText={formErrors.b2_ind_dec_5 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Have you ever defaulted on a loan?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                 name="b2_ind_dec_6"
                 value={formData.b2_ind_dec_6}
                 onChange={handleInputChange}
                 error={!!formErrors.b2_ind_dec_6}
                 helperText={formErrors.b2_ind_dec_6 || ""}
                  row
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
                required
                label="Please Explain Any Yes Answers"
                name="b2_ind_dec_desc"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.b2_ind_dec_desc}
                onChange={handleInputChange}
                error={!!formErrors.b2_ind_dec_desc}
                helperText={formErrors.b2_ind_dec_desc || "If any answers above are yes, please example in detail"}
            />
          </Grid>
        </Grid>
        )}



        {formData.main_borrower === "Entity" && (
        <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Entity Borrower #1 Info
          </Divider>
          <Grid item xs={12}>
            <TextField
                required
                label="Full Name of Legal Entity"
                name="ent_name"
                fullWidth
                variant="outlined"
                value={formData.ent_name}
                onChange={handleInputChange}
                error={!!formErrors.ent_name}
                helperText={formErrors.ent_name || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Entity Mailing Address"
                name="ent_address"
                fullWidth
                variant="outlined"
                value={formData.ent_address}
                onChange={handleInputChange}
                error={!!formErrors.ent_address}
                helperText={formErrors.ent_address || "Please include full address including street, city, state and zip code"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Tax ID Number"
                name="ent_tax_id"
                fullWidth
                variant="outlined"
                value={formData.ent_tax_id}
                onChange={handleInputChange}
                error={!!formErrors.ent_tax_id}
                helperText={formErrors.ent_tax_id || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity State of Formation"
                name="ent_state_form"
                fullWidth
                variant="outlined"
                value={formData.ent_state_form}
                onChange={handleInputChange}
                error={!!formErrors.ent_state_form}
                helperText={formErrors.ent_state_form || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Entity Owner Name"
                name="b1_ent_name"
                fullWidth
                variant="outlined"
                value={formData.b1_ent_name}
                onChange={handleInputChange}
                error={!!formErrors.b1_ent_name}
                helperText={formErrors.b1_ent_name || "Person who will sign documents and act on behalf of entity"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner Email"
                name="b1_ent_email"
                fullWidth
                variant="outlined"
                value={formData.b1_ent_email}
                onChange={handleInputChange}
                error={!!formErrors.b1_ent_email}
                helperText={formErrors.b1_ent_email || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner Phone Number"
                name="b1_ent_phone"
                fullWidth
                variant="outlined"
                value={formData.b1_ent_phone}
                onChange={handleInputChange}
                error={!!formErrors.b1_ent_phone}
                helperText={formErrors.b1_ent_phone || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner Social Security Number"
                name="b1_ent_ssn"
                fullWidth
                variant="outlined"
                value={formData.b1_ent_ssn}
                onChange={handleInputChange}
                error={!!formErrors.b1_ent_ssn}
                helperText={formErrors.b1_ent_ssn || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner Date of Birth"
                name="b1_ent_dob"
                fullWidth
                variant="outlined"
                value={formData.b1_ent_dob}
                onChange={handleInputChange}
                error={!!formErrors.b1_ent_dob}
                helperText={formErrors.b1_ent_dob || ""}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
                required
                label="Entity Owner Address"
                name="b1_ent_address"
                fullWidth
                variant="outlined"
                value={formData.b1_ent_address}
                onChange={handleInputChange}
                error={!!formErrors.b1_ent_address}
                helperText={formErrors.b1_ent_address || ""}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are There Other Owners?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  name="ent_co_borrower"
                  value={formData.ent_co_borrower}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_co_borrower}
                  helperText={formErrors.ent_co_borrower || ""}
                  row
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          </Grid>)}



        { formData.main_borrower === "Entity" && formData.ent_co_borrower === "Yes" && (
        <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Entity Borrower #2 Info
          </Divider>
          <Grid item xs={12}>
            <TextField
                required
                label="Entity Owner #2 Name"
                name="b2_ent_name"
                fullWidth
                variant="outlined"
                value={formData.b2_ent_name}
                onChange={handleInputChange}
                error={!!formErrors.b2_ent_name}
                helperText={formErrors.b2_ent_name || "2nd Person who will sign documents and act on behalf of entity"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner #2 Email"
                name="b2_ent_email"
                fullWidth
                variant="outlined"
                value={formData.b2_ent_email}
                onChange={handleInputChange}
                error={!!formErrors.b2_ent_email}
                helperText={formErrors.b2_ent_email || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner #2 Phone Number"
                name="b2_ent_phone"
                fullWidth
                variant="outlined"
                value={formData.b2_ent_phone}
                onChange={handleInputChange}
                error={!!formErrors.b2_ent_phone}
                helperText={formErrors.b2_ent_phone || ""}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner #2 Social Security Number"
                name="b2_ent_ssn"
                fullWidth
                variant="outlined"
                value={formData.b2_ent_ssn}
                onChange={handleInputChange}
                error={!!formErrors.b2_ent_ssn}
                helperText={formErrors.b2_ent_ssn || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity Owner #2 Date of Birth"
                name="b2_ent_dob"
                fullWidth
                variant="outlined"
                value={formData.b2_ent_dob}
                onChange={handleInputChange}
                error={!!formErrors.b2_ent_dob}
                helperText={formErrors.b2_ent_dob || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Entity Owner #2 Address"
                name="b2_ent_address"
                fullWidth
                variant="outlined"
                value={formData.b2_ent_address}
                onChange={handleInputChange}
                error={!!formErrors.b2_ent_address}
                helperText={formErrors.b2_ent_address || ""}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
                required
                label="Entity Owner #2 Percentage Ownership"
                name="b2_ent_percent"
                fullWidth
                variant="outlined"
                value={formData.b2_ent_percent}
                onChange={handleInputChange}
                error={!!formErrors.b2_ent_percent}
                helperText={formErrors.b2_ent_percent || ""}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are There Other Owners?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="ent_co_borrower2"
                  value={formData.ent_co_borrower2}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_co_borrower2}
                  helperText={formErrors.ent_co_borrower2 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          </Grid>)}

          { formData.main_borrower === "Entity" && formData.ent_co_borrower === "Yes" && formData.ent_co_borrower2 === "Yes" && (
          <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Other Entity Borrower Info
          </Divider>
          <Grid item xs={12}>
            <TextField
                required
                label="Please List All Other Owner Names, Addresses, SSN, and Percentage Interest In The Company"
                name="other_ent_desc"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formData.other_ent_desc}
                onChange={handleInputChange}
                error={!!formErrors.other_ent_desc}
                helperText={formErrors.other_ent_desc || ""}
            />
          </Grid>
          </Grid>)}


          { formData.main_borrower === "Entity" && (
          <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Entity Financial Info
          </Divider>
          <Grid item xs={12} md={6}>
            <TextField
            required
            select
            label="Entity Owner Credit"
            fullWidth
            name="ent_owner_credit"
            value={formData.ent_owner_credit}
            onChange={handleInputChange}
            error={!!formErrors.ent_owner_credit}
            helperText={formErrors.ent_owner_credit || ""}
            >
                <MenuItem key="Poor 300-579" value="Poor 300-579">Poor 300-579 </MenuItem>
                <MenuItem key="Fair 580-669" value="Fair 580-669">Fair 580-669</MenuItem>
                <MenuItem key="Good 670-739" value="Good 670-739">Good 670-739</MenuItem>
                <MenuItem key="Very Good 740-799" value="Very Good 740-799">Very Good 740-799</MenuItem>
                <MenuItem key="Excellent 800-850" value="Excellent 800-850">Excellent 800-850</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Entity/Owner Total Cash Reserves"
                name="ent_owner_reserves"
                fullWidth
                variant="outlined"
                value={formData.ent_owner_reserves}
                onChange={handleInputChange}
                error={!!formErrors.ent_owner_reserves}
                helperText={formErrors.ent_owner_reserves || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Last Calendar Year GROSS Income Attributable to This Entity:"
                name="ent_owner_gross"
                fullWidth
                variant="outlined"
                value={formData.ent_owner_gross}
                onChange={handleInputChange}
                error={!!formErrors.ent_owner_gross}
                helperText={formErrors.ent_owner_gross || "TOTAL income before any expenses"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="Last Calendar Year NET Income Attributable to This Entity:"
                name="ent_owner_net"
                fullWidth
                variant="outlined"
                value={formData.ent_owner_net}
                onChange={handleInputChange}
                error={!!formErrors.ent_owner_net}
                helperText={formErrors.ent_owner_net || "Income AFTER operating expenses but BEFORE any debt service"}
            />
          </Grid>



          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Entity Financial Declarations
          </Divider>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Are there any outstanding judgments against entity or any owners of entity?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="ent_dec_1"
                  value={formData.ent_dec_1}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_dec_1}
                  helperText={formErrors.ent_dec_1 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Has entity or any owner declared bankruptcy the past 7 years?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="ent_dec_2"
                  value={formData.ent_dec_2}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_dec_2}
                  helperText={formErrors.ent_dec_2 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Is entity or any owner a party to any current lawsuits?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="ent_dec_3"
                  value={formData.ent_dec_3}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_dec_3}
                  helperText={formErrors.ent_dec_3 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Does entity or any owner have any unpaid federal or state taxes?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="ent_dec_4"
                  value={formData.ent_dec_4}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_dec_4}
                  helperText={formErrors.ent_dec_4 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Has entity or any owner ever had any property you own foreclosed against?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="ent_dec_5"
                  value={formData.ent_dec_5}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_dec_5}
                  helperText={formErrors.ent_dec_5 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Has entity or any owner ever defaulted on a loan?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="ent_dec_6"
                  value={formData.ent_dec_6}
                  onChange={handleInputChange}
                  error={!!formErrors.ent_dec_6}
                  helperText={formErrors.ent_dec_6 || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Explain Any Yes Answers Here"
                name="ent_dec_desc"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.ent_dec_desc}
                onChange={handleInputChange}
                error={!!formErrors.ent_dec_desc}
                helperText={formErrors.ent_dec_desc || ""}
            />
          </Grid>
            </Grid>)}


          <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Loan Request Details
          </Divider>
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="What Is The Total Requested Loan Amount?"
                name="loan_req_amount"
                fullWidth
                variant="outlined"
                value={formData.loan_req_amount}
                onChange={handleInputChange}
                error={!!formErrors.loan_req_amount}
                helperText={formErrors.loan_req_amount || ""}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
                required
                label="When Do You Need To Close By?"
                name="loan_req_close"
                fullWidth
                variant="outlined"
                value={formData.loan_req_close}
                onChange={handleInputChange}
                error={!!formErrors.loan_req_close}
                helperText={formErrors.loan_req_close || "MM/DD/YYYY"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
            required
            select
            label="How Long Will You Need The Loan?"
            fullWidth
            name="loan_req_length"
            value={formData.loan_req_length}
            onChange={handleInputChange}
            error={!!formErrors.loan_req_length}
            helperText={formErrors.loan_req_length || ""}
            >
                <MenuItem key="6 Months" value="6 Months"> 6 Months </MenuItem>
                <MenuItem key="9 Months" value="9 Months"> 9 Months </MenuItem>
                <MenuItem key="1 Year" value="1 Year"> 1 Year </MenuItem>
                <MenuItem key="18 Months" value="18 Months"> 18 Months </MenuItem>
                <MenuItem key="2 Years" value="2 Years"> 2 Years </MenuItem>
                <MenuItem key="2+ Years" value="2+ Years"> 2+ Years </MenuItem>
                <MenuItem key="Other" value="Other"> Other </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
            required
            select
            label="Is This a Refinance or New Financing?"
            fullWidth
            name="loan_req_finance"
            value={formData.loan_req_finance}
            onChange={handleInputChange}
            error={!!formErrors.loan_req_finance}
            helperText={formErrors.loan_req_finance || ""}
            >
                <MenuItem key="Refinance of Existing Debt" value="Refinance of Existing Debt"> Refinance of Existing Debt </MenuItem>
                <MenuItem key="New Financing" value="New Financing"> New Financing </MenuItem>
            </TextField>
          </Grid>
          </Grid>




        { formData.loan_req_finance === "Refinance of Existing Debt" && (
          <Grid container spacing={2}>
          {/*Existing Loan */}
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Existing Loan Details
          </Divider>
          <Grid item xs={12} md={12}>
            <TextField
                required
                label="What Is The Estimated Current Loan Balance Being Paid Off?"
                name="loan_exist_balance"
                fullWidth
                variant="outlined"
                value={formData.loan_exist_balance}
                onChange={handleInputChange}
                error={!!formErrors.loan_exist_balance}
                helperText={formErrors.loan_exist_balance || ""}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
            required
            select
            label="Loan Default/Foreclosure Status"
            fullWidth
            name="loan_exist_status"
            value={formData.loan_exist_status}
            onChange={handleInputChange}
            error={!!formErrors.loan_exist_status}
            helperText={formErrors.loan_exist_status || ""}
            >
                <MenuItem key="No default or foreclosure" value="No default or foreclosure"> Neither default nor foreclosure </MenuItem>
                <MenuItem key="Only Default" value="Only Default"> Only Default </MenuItem>
                <MenuItem key="Active foreclosure" value="Active foreclosure"> Active foreclosure </MenuItem>

            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Add Any Additional Info About Your Existing Loan:"
                name="loan_exist_desc"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.loan_exist_desc}
                onChange={handleInputChange}
                error={!!formErrors.loan_exist_desc}
                helperText={formErrors.loan_exist_desc || "If any answers above are yes, please example in detail"}
            />
          </Grid>
          </Grid>)}


          <Grid container spacing={2}>
          {/*Existing Loan */}
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Use of Funds Details
          </Divider>
          {/* Use of Funds */}
          <Grid item xs={12} >
            <TextField
            required
            select
            label="What Are You Using the Loan Proceeds For?"
            fullWidth
            name="use_of_funds"
            value={formData.use_of_funds}
            onChange={handleInputChange}
            error={!!formErrors.use_of_funds}
            helperText={formErrors.use_of_funds || ""}
            >
                <MenuItem key="Taking New Financing on Property I Own Already" value="Taking New Financing on Property I Own Already"> Taking New Financing on Property I Own Already </MenuItem>
                <MenuItem key="Purchasing Property" value="Purchasing Property"> Purchasing Property </MenuItem>
            </TextField>
          </Grid>
          </Grid>


          {formData.use_of_funds === "Purchasing Property" && (
          <Grid container spacing={2} sx={{mt: 1}}>
            
          {/* Purchase Price */}
          <Grid item xs={12} >
            <TextField
                required
                label="What is the Purchase Price of the Property?"
                name="purchase_price"
                fullWidth
                variant="outlined"
                value={formData.purchase_price}
                onChange={handleInputChange}
                error={!!formErrors.purchase_price}
                helperText={formErrors.purchase_price || ""}
            />
          </Grid>
          </Grid>)}



          <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Collateral Details
          </Divider>
          <Grid item xs={12}>
            <TextField
                required
                label="List Address(es) of ALL Real Estate Being Offered as Collateral"
                name="collat_addr"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formData.collat_addr}
                onChange={handleInputChange}
                error={!!formErrors.collat_addr}
                helperText={formErrors.collat_addr || "Include street #, city, state, zip for collateral. If multiple properties, include FULL address for each piece of collateral"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Estimated Total Value of All Real Estate Assets Being Offered as Collateral"
                name="collat_value"
                fullWidth
                variant="outlined"
                value={formData.collat_value}
                onChange={handleInputChange}
                error={!!formErrors.collat_value}
                helperText={formErrors.collat_value || "Combined value of all pieces of collateral. For construction loans, use current, as-is value"}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl>
              <FormLabel>
                What Type of Real Estate Collateral Is Being Used For This Loan? (check all that apply)
              </FormLabel>
              {[
                'Land Only',
                'Detached / Single Family Dwelling',
                'Townhouse/Rowhouse',
                'Condo',
                'Multi-Unit (1-4)',
                'Multi-Unit (5+ units)',
                'Mixed-Use',
                'Commercial Building(s)',
                'Warehouse',
                'Other',
              ].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  name="collat_use"
                  control={
                    <Checkbox
                      checked={selectedCollatUse.includes(option)}
                      onChange={handleCheckboxChange}
                      value={option}
                    />
                  }
                  label={option}
                />
              ))}
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <TextField
                required
                label="Any Additional Information Regarding The Collateral"
                name="collat_desc"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.collat_desc}
                onChange={handleInputChange}
                error={!!formErrors.collat_desc}
                helperText={formErrors.collat_desc || "If any answers above are yes, please example in detail"}
            />
          </Grid>
        </Grid>





        <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Contruction/Rehab Details
          </Divider>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Will This Project Require Construction/Rehab?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="construct"
                  value={formData.construct}
                  onChange={handleInputChange}
                  error={!!formErrors.construct}
                  helperText={formErrors.construct || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
            
          {formData.construct === "Yes" && (
            <Grid container spacing={2} sx={{mt: 1}}>
          {/*If YES */}
         
          <Grid item xs={12}>
            <TextField
                required
                label="Estimated Dollar Amount of Construction Required:"
                name="construct_price"
                fullWidth
                variant="outlined"
                value={formData.construct_price}
                onChange={handleInputChange}
                error={!!formErrors.construct_price}
                helperText={formErrors.construct_price || ""}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
                required
                label="What Is The Estimated as-is Value of the Property Before Construction?"
                name="construct_as_value"
                fullWidth
                variant="outlined"
                value={formData.construct_as_value}
                onChange={handleInputChange}
                error={!!formErrors.construct_as_value}
                helperText={formErrors.construct_as_value || ""}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
                required
                label="What Is The Estimated after-repair Value of the Property After Construction?"
                name="construct_after_value"
                fullWidth
                variant="outlined"
                value={formData.construct_after_value}
                onChange={handleInputChange}
                error={!!formErrors.construct_after_value}
                helperText={formErrors.construct_after_value || ""}
            />
          </Grid>
          </Grid>)}




          <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
             Exit Strategy
          </Divider>
          <Grid item xs={12} md={12}>
            <TextField
            required
            select
            label="How Do You Intend To Exit The Loan?"
            fullWidth
            name="exit_strat"
            value={formData.exit_strat}
            onChange={handleInputChange}
            error={!!formErrors.exit_strat}
            helperText={formErrors.exit_strat || ""}
            >
                <MenuItem key="Sell Property" value="Sell Property"> Sell Property </MenuItem>
                <MenuItem key="Refinance Property" value="Refinance Property"> Refinance Property </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
             Borrower Experience
          </Divider>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Do You Own Other Investment Properties?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="other_prop"
                  value={formData.other_prop}
                  onChange={handleInputChange}
                  error={!!formErrors.other_prop}
                  helperText={formErrors.other_prop || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="If Yes Please Enter Address(es) of Other Properties:"
                name="other_prop_desc"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.other_prop_desc}
                onChange={handleInputChange}
                error={!!formErrors.other_prop_desc}
                helperText={formErrors.other_prop_desc || ""}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{mt:1}}>Have You Done Projects Similar To This Before?</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl>
                <RadioGroup
                  row
                  name="similar_proj"
                  value={formData.similar_proj}
                  onChange={handleInputChange}
                  error={!!formErrors.similar_proj}
                  helperText={formErrors.similar_proj || ""}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>


        <Grid container spacing={2}>
          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
             Additional Info
          </Divider>
          <Grid item xs={12}>
            <TextField
                required
                label="Questions/Additional Info"
                name="additional_desc"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.additional_desc}
                onChange={handleInputChange}
                error={!!formErrors.additional_desc}
                helperText={formErrors.additional_desc || "Please list any other information about your request that you think relevant"}
            />
          </Grid>

          <Grid item xs={12} md={10}/>
          <Grid item xs={12} md={2}>
            <Typography sx={{mt:1}}> <span style={{ textDecoration: 'underline' }}>I Agree </span></Typography>
          </Grid>
           
          <Grid item xs={12} md={10}>
            <Typography sx={{mt:1}}>Lender will require property valuation/appraisal prior to approving your loan</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl>
              <RadioGroup
                row
                name="legal_dec_1"
                value={formData.legal_dec_1}
                //onChange={handleSingleCheckboxChange} // Use your checkbox-specific handler
                error={!!formErrors.legal_dec_1}
                helperText={formErrors.legal_dec_1 || ""}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Checkbox
                      name="legal_dec_1"
                      checked={formData.legal_dec_1 === 'Yes'}
                      onChange={handleSingleCheckboxChange}
                    />
                  }
                  label="Yes"
                  labelPlacement="end" // Optional: Adjust label placement
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography sx={{mt:1}}>Borrower responsible for all lender, title, appraisal, due diligence or 3rd party costs</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl>
              <RadioGroup
                row
                name="legal_dec_2"
                value={formData.legal_dec_2}
                //onChange={handleSingleCheckboxChange} // Use your checkbox-specific handler
                error={!!formErrors.legal_dec_2}
                helperText={formErrors.legal_dec_2 || ""}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Checkbox
                      name="legal_dec_2"
                      checked={formData.legal_dec_2 === 'Yes'}
                      onChange={handleSingleCheckboxChange}
                    />
                  }
                  label="Yes"
                  labelPlacement="end" // Optional: Adjust label placement
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography sx={{mt:1}}>All due diligence fees paid are non-refundable</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl>
              <RadioGroup
                row
                name="legal_dec_3"
                value={formData.legal_dec_3}
                //onChange={handleSingleCheckboxChange} // Use your checkbox-specific handler
                error={!!formErrors.legal_dec_3}
                helperText={formErrors.legal_dec_3 || ""}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Checkbox
                      name="legal_dec_3"
                      checked={formData.legal_dec_3 === 'Yes'}
                      onChange={handleSingleCheckboxChange}
                    />
                  }
                  label="Yes"
                  labelPlacement="end" // Optional: Adjust label placement
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography sx={{mt:1}}>Subject Property may not be borrower's primary residence. All loan proceeds will be used for commercial, business & investment purposes</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl>
              <RadioGroup
                row
                name="legal_dec_4"
                value={formData.legal_dec_4}
                //onChange={handleSingleCheckboxChange} // Use your checkbox-specific handler
                error={!!formErrors.legal_dec_4}
                helperText={formErrors.legal_dec_4 || ""}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Checkbox
                      name="legal_dec_4"
                      checked={formData.legal_dec_4 === 'Yes'}
                      onChange={handleSingleCheckboxChange}
                    />
                  }
                  label="Yes"
                  labelPlacement="end" // Optional: Adjust label placement
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>


        
        <Button
          type="submit"
          variant="contained"
          color="info"
          fullWidth
          size="large"
          sx={{mt: 3, color: 'white'}}
          onClick={confirmSubmit}
        >
          Submit
        </Button>
        <Popover
          open={openPopover}
          anchorEl={anchorSubmit}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography sx={{p:2}}> Are you sure you want to submit?</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1}}>
              <Button onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="success">
                Sumbit
              </Button>
          </Box>
        </Popover>

        <Snackbar
          open={alertOn}
          autoHideDuration={5000}
          onClose={alertClose}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='success'
            variant='filled'
            anchorOrigin={
              { vertical: 'bottom', horizontal: 'center' }
            }
          >
            Application Submitted
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertErrorOn}
          autoHideDuration={8000}
          onClose={alertClose}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='error'
            variant='filled'
            anchorOrigin={
              { vertical: 'bottom', horizontal: 'center' }
            }
          >
            Missing Required Info
          </Alert>
        </Snackbar>
      </form>
    </Container>
  );
}

export default LoanFormPage;
