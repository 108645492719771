import * as React from 'react';
import withRoot from './withRoot';

import ProductHero from './PromotionsHero';
import PhotoTextLeft from './PhotoTextLeft';
import PhotoTextRight from './PhotoTextRight';
import BottomVideo from './BottomVideo';
import PhotoGallery from './PhotoGallery';
import CanvaEmbed from './CanvaEmbed';
import CarouselElement from './Carousel2';

function Index() {
  return (
    <React.Fragment>
      <ProductHero/>
      <PhotoTextLeft/>
      <PhotoTextRight/>
      <CanvaEmbed/>
      <BottomVideo/>
     
    </React.Fragment>
  );
}

export default withRoot(Index);
