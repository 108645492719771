import React from "react";
import Typography from "@mui/material/Typography";

const CanvaEmbed = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "rgba(23, 23, 23, 1)",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          borderBottom: "2px solid white",
          color: "white",
          fontSize: { xs: "2rem", sm: "3rem", md: "2rem", lg: "3rem" },
          textAlign: { xs: "center", md: "center" },
          mt: 3,
        }}
      >
        <span style={{ fontWeight: "bold" }}>Sponsorship Opportunities</span>
      </Typography>
      <Typography
        component="a"
        href="https://www.canva.com/design/DAGaKaonKy0/-B2qbYIJHiT-0WW84pcyyA/view?utm_content=DAGaKaonKy0&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          //color: "white",
          fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem", lg: "1.5rem" },
          textAlign: { xs: "center", md: "center" },
          //textDecoration: "none",
          mb: 2,
        }}
      >
        Skyy Myles Promotions Sponsorship Slides
      </Typography>

      <div
        style={{
          position: "relative",
          width: "90%",
          height: 0,
          paddingTop: "56.25%", // Aspect ratio for 16:9
          backgroundColor: "rgba(23, 23, 23, 1)",
          boxShadow: "0 2px 8px 0 rgba(23, 23, 23,0.16)",
          overflow: "hidden",
          borderRadius: "8px",
          willChange: "transform",
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
          }}
          src="https://www.canva.com/design/DAGaKaonKy0/-B2qbYIJHiT-0WW84pcyyA/view?embed"
          allowFullScreen
        ></iframe>
      </div>

      <Typography

        gutterBottom
        sx={{
          color: "white",
          fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem", lg: "1.5rem" },
          textAlign: { xs: "center", md: "center" },
          mt: 3,
        }}
      >
        Contact us for more sponsorship opportunities or information.
      </Typography>
    </div>
  );
};

export default CanvaEmbed;
