import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  MenuItem,
  Popover,
  Box,
  Alert,
  Snackbar
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { Buffer } from 'buffer';
import axios from 'axios';
//import { response } from 'express';
import config from '../../../../config.js';


function UpdatePropertyPage() {
  const [formData, setFormData] = useState({
    addressL1: "",
    addressL2: "",
    city: "",
    state: "",
    zip: "",
    description: "",
    bathrooms: "",
    bedrooms: "",
    date: "",
    image: "",
    negotiable: "",
    price: "",
    rentprice: "",
    salemethod: "",
    status: "",
    type: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [anchorSubmit, setAnchorSubmit] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [mainPicture, setMainPicture] = useState([]);
  const [otherPictures, setOtherPictures] = useState([]);
  const [propertyNames, setPropertyNames] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); // State to hold the selected item
  const [errorPop, setErrorPop] = useState(false);
  const [errorCodePop, setErrorCodePop] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
  // Set the base URL for Axios requests
  const api = axios.create({
    baseURL:  config[environment].apiUrl.replace('/api', ''),
  });


  useEffect(() => {
    // Function to fetch items from DynamoDB
    const fetchItems = async () => {
      //Get Data from AWS DynamoDB
      api.get('/api/getPropertyNames')
        .then((response) => {
          //console.log("Get Prop to DynamoDB: ", response.data);
          setPropertyNames(response.data);
        })
        .catch((error) => {
          console.log("Prop DB Get:", error);
        })
    };
  
    fetchItems(); // Call the function when the component mounts
  }, []);


  //Handle Single Picture
  const handlePictureChange = (e) => {
    //console.log("Main Pic:", e.target.files[0])
    setMainPicture(e.target.files[0]);
    formData.image = e.target.files[0].name;
  };

  //Handle Multiple Pictures
  const handlePictureChangeMultiple = (e) => {
    //console.log("Other Pics:", e.target.files)
    const files = Array.from(e.target.files);
    setOtherPictures(files);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    //console.log("Change:", name, value);
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const confirmSubmit = (e) => {
    e.preventDefault();
    setAnchorSubmit(e.currentTarget);
    setOpenPopover(true);
  }

  const handleClose = () => {
    setAnchorSubmit(null);
    setOpenPopover(false);
  }

  const alertClose = () => {
    setAlertOn(false);
    setErrorPop(false);
  }

  const handleSubmit = async (e) => {
    //console.log("Form Submitting");
    e.preventDefault();
    setOpenPopover(false);
    setAnchorSubmit(null);
    setSubmitLoading(true)

    // Validate the form fields
    const errors = {};
    //console.log("Form Data:", formData);
    for (const key in formData) {
      if (formData[key] === '' && 
        key !== 'description' && key !== 'rentprice' 
        && key !== 'price' && key !== 'addressL2'
        && key !== 'image'
        ) {
        errors[key] = 'This field is required';
      }
    }
    
    // If there are validation errors, update the state and prevent submission
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setErrorPop(true);
      setSubmitLoading(false)
      console.log("Errors:", errors);
      return;
    }

    //AWS S3 Bucket Property Photos Submission
    const bucketName = 'skyymylesproperties';
    try {      
      //Transfer Other/Gallery Pictures to S3
      for (const pic of otherPictures){
        const key = formData.addressL1 + '/' + pic.name;
        const fileContent = await readFileAsBuffer(pic);
        const response = await api.post('/api/putS3Bucket', {
          Bucket: bucketName,
          Key: key,
          Body: fileContent,
          ContentType: getFileContentType(pic),
        });
      }

      //Transfer Main/Display Picture to S3
      const key = formData.addressL1 + '/front.' + mainPicture.name.split('.').pop();
      const fileContent = await readFileAsBuffer(mainPicture);
      const response = await api.post('/api/putS3Bucket', {
        Bucket: bucketName,
        Key: key,
        Body: fileContent,
        ContentType: getFileContentType(mainPicture),
      });
        
    } catch (error) {
      console.error('Error uploading data to S3:', error);
    }

    //Strip tailing Object value spaces
    for (const key in formData) {
      if (typeof formData[key] === 'string') {
        formData[key] = formData[key].trimEnd(); // Remove trailing whitespace
      }
    }

    //Send Data to AWS DynamoDB
    //console.log("Sending Prop Data to DynamoDB");
    api.post('/api/addProperty', formData)
      .then((response) => {
        //console.log("Add Prop to DynamoDB: ", response.data);
        setAlertOn(true);
        setSubmitLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false)
        setErrorCodePop(true)
      })

      setAlertOn(true);
      setSubmitLoading(false)
  };

  // Utility function to read a file as a Buffer
  const readFileAsBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(Buffer.from(event.target.result));
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  // Utility function to get the content type of a file based on its extension
  const getFileContentType = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
      return 'image/jpeg';
    } else if (fileExtension === 'png') {
      return 'image/png';
    } else {
      return 'application/octet-stream'; // Fallback content type
    }
  };

  const getSelectedProperty = (propName) => {
    //console.log("Getting Prop:", propName);
    api.get(`/api/getPropertyDetails?propName=${propName}`)
      .then((response) => {
        //console.log("Get Prop Details: ", response.data);
        const prop = response.data;

        //Fix Negotiable Output
        if(prop.negotiable === "N/A"){
          prop.negotiable = "No";
        }
        
        setFormData({
          addressL1: prop.address,
          addressL2: prop.address2,
          city: prop.city,
          state: prop.state,
          zip: prop.zip,
          description: prop.description,
          bathrooms: prop.bathrooms,
          bedrooms: prop.bedrooms,
          date: prop.date,
          image: prop.image,
          negotiable: prop.negotiable,
          price: prop.price,
          rentprice: prop.rentprice,
          salemethod: prop.salemethod,
          status: prop.status,
          type: prop.type,
        })
      })
      .catch((error) => {
        console.log(error);
      })

  }

  return (
    <Container maxWidth="md" backgroundColor="#f9f9f9" 
      sx={{
        mb: {xs: 0, md: 5}, 
        mt: {xs: 0, md: 5}, 
      }}
    >
      <form >
        <Typography variant="h2" align="center" sx={{mt: {xs: 0, md: 2}, }}>
          Update Property
        </Typography>
        <Typography  fullWidth variant="h6" align="center" >
            Select Property to Update
        </Typography>
        
        <TextField
          select
          label="Select Property"
          name="selectedProperty"
          fullWidth
          variant="outlined"
          value={selectedItem ? selectedItem : ''}
          onChange={(e) => {
            //console.log("PropName:", e.target.value)
            const selectedItem = e.target.value;
            setSelectedItem(selectedItem);
            getSelectedProperty(selectedItem);
          }}
          onTouchStart={(e) => {
            const selectedItem = e.target.value;
            setSelectedItem(selectedItem);
            getSelectedProperty(selectedItem);
          }}
          sx={{
            mt: 2,
            mb: 2
          }}
        >
          {propertyNames.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>

        
        <Grid container spacing={2}>

          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Property Address
          </Divider>
   
          <Grid item xs={12}>
            <TextField
                required
                label="Address Line 1"
                name="addressL1"
                fullWidth
                variant="outlined"
                value={formData.addressL1}
                onChange={handleInputChange}
                error={!!formErrors.addressL1}
                helperText={formErrors.addressL1 || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                label="Address Line 2 - Unit/Apt Number"
                name="addressL2"
                fullWidth
                variant="outlined"
                value={formData.addressL2}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="City"
                name="city"
                fullWidth
                variant="outlined"
                value={formData.city}
                onChange={handleInputChange}
                error={!!formErrors.city}
                helperText={formErrors.city || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="State"
                name="state"
                fullWidth
                variant="outlined"
                value={formData.state}
                onChange={handleInputChange}
                error={!!formErrors.state}
                helperText={formErrors.state || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="ZIP Code"
                name="zip"
                fullWidth
                variant="outlined"
                value={formData.zip}
                onChange={handleInputChange}
                error={!!formErrors.zip}
                helperText={formErrors.zip || ""}
            />
          </Grid>

          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Property Details
          </Divider>

          <Grid item xs={12}>
            <TextField
                required
                label="Property Type"
                name="type"
                fullWidth
                variant="outlined"
                value={formData.type}
                onChange={handleInputChange}
                error={!!formErrors.type}
                helperText={formErrors.type || "Ex. Townhouse, Apartment, etc."}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
            required
            select
            label="Sale Method/Type"
            name="salemethod"
            fullWidth
            value={formData.salemethod}
            onChange={handleInputChange}
            error={!!formErrors.salemethod}
            helperText={formErrors.salemethod || ""}
            >
                <MenuItem key="wholesale" value="wholesale">Wholesale</MenuItem>
                <MenuItem key="rental" value="rental">Rental</MenuItem>
                <MenuItem key="turnkey" value="turnkey">Turnkey</MenuItem>
            </TextField>
          </Grid>

          
          {(formData.salemethod === "wholesale" || formData.salemethod === "") &&
          <Grid item xs={12}>
            <TextField
                required
                label="Price"
                name="price"
                fullWidth
                variant="outlined"
                value={formData.price}
                onChange={handleInputChange}
                error={!!formErrors.price}
                helperText={formErrors.price || "Wholesale Price. Only Numbers, No Symbols or Punctuation. Ex) 75000"}
            />
          </Grid>
          }
          {formData.salemethod === "rental" &&
          <Grid item xs={12}>
            <TextField
                required
                label="Rent"
                name="rentprice"
                fullWidth
                variant="outlined"
                value={formData.rentprice}
                onChange={handleInputChange}
                error={!!formErrors.rentprice}
                helperText={formErrors.rentprice || "Rent Per Month. Only Numbers, No Symbols or Punctuation. Ex) 75000"}
            />
          </Grid>
          }
          {formData.salemethod === "turnkey" &&
          <Grid item xs={12}>
            <TextField
                required
                label="Turkey Price"
                name="price"
                fullWidth
                variant="outlined"
                value={formData.price}
                onChange={handleInputChange}
                error={!!formErrors.price}
                helperText={formErrors.price || "Turkey Wholesale Price. Only Numbers, No Symbols or Punctuation. Ex) 75000"}
            />
          </Grid>
          }
          {formData.salemethod === "turnkey" &&
            <Grid item xs={12}>
              <TextField
                  required
                  label="Turkey Rent"
                  name="rentprice"
                  fullWidth
                  variant="outlined"
                  value={formData.rentprice}
                  onChange={handleInputChange}
                  error={!!formErrors.rentprice}
                  helperText={formErrors.rentprice || "Turkey Rent Per Month. Only Numbers, No Symbols or Punctuation. Ex) 75000"}
              />
          </Grid>
          }


          <Grid item xs={12}>
            <TextField
                required
                label="Listed Date"
                name="date"
                fullWidth
                variant="outlined"
                value={formData.date}
                onChange={handleInputChange}
                error={!!formErrors.date}
                helperText={formErrors.date || "The date property was listed"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            required
            select
            label="Negotiable Price?"
            name="negotiable"
            fullWidth
            value={formData.negotiable}
            onChange={handleInputChange}
            error={!!formErrors.negotiable}
            helperText={formErrors.negotiable || ""}
            >
              <MenuItem key="No" value="No">No</MenuItem>
              <MenuItem key="Yes" value="Yes">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
            required
            select
            label="Property Status?"
            name="status"
            fullWidth
            value={formData.status}
            onChange={handleInputChange}
            error={!!formErrors.status}
            helperText={formErrors.status || ""}
            >
              <MenuItem key="active" value="active">Active</MenuItem>
              <MenuItem key="sold" value="sold">Sold</MenuItem>
              <MenuItem key="deactive" value="deactive">Deactive</MenuItem>
            </TextField>
          </Grid>


          <Grid item xs={6}>
            <TextField
                required
                label="Bedrooms"
                name="bedrooms"
                fullWidth
                variant="outlined"
                value={formData.bedrooms}
                onChange={handleInputChange}
                error={!!formErrors.bedrooms}
                helperText={formErrors.bedrooms || "Number of Bedrooms"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                required
                label="Bathrooms"
                name="bathrooms"
                fullWidth
                variant="outlined"
                value={formData.bathrooms}
                onChange={handleInputChange}
                error={!!formErrors.bathrooms}
                helperText={formErrors.bathrooms || "Number of Bathrooms"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                label="Property Description"
                name="description"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formData.description}
                onChange={handleInputChange}
            />
          </Grid>


          <Grid item xs={12}>
            <Typography> 
                Main/Display Picture:
                &nbsp;&nbsp;&nbsp;
                <input type="file" accept="image/*" onChange={handlePictureChange}/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography> 
                Other/Gallery Pictures:
                &nbsp;&nbsp;&nbsp;
                <input type="file" accept="image/*" multiple onChange={handlePictureChangeMultiple} />
            </Typography>
            
          </Grid>
        </Grid>
        

      {selectedItem !== null && formData !== null &&
        <LoadingButton
          type="submit"
          variant="contained"
          color="info"
          fullWidth
          size="large"
          sx={{mt: 3, color: 'white'}}
          onClick={confirmSubmit}
          loadingPosition="start"
          loading={submitLoading}
        >
          Submit
        </LoadingButton>
      }
        <Popover
          open={openPopover}
          anchorEl={anchorSubmit}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography sx={{p:2}}> Are you sure you want to submit?</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1}}>
              <Button onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="success">
                Sumbit
              </Button>
          </Box>
        </Popover>

        <Snackbar
          open={alertOn}
          autoHideDuration={4000}
          onClose={alertClose}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='success'
            variant='filled'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            Property Updated
          </Alert>
        </Snackbar>

        <Snackbar
          open={errorPop}
          autoHideDuration={4000}
          onClose={alertClose}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='error'
            variant='filled'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            Error Submitting - Check Required Fields
          </Alert>
        </Snackbar>

        <Snackbar
          open={errorCodePop}
          autoHideDuration={4000}
          onClose={alertClose}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='error'
            variant='filled'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            Code Error
          </Alert>
        </Snackbar>
      </form>
    </Container>
  );
}

export default UpdatePropertyPage;
