import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    useTheme,
    IconButton,
    Grid,
} from '@mui/material';

import { ArrowBack, ArrowLeft, ArrowRight, ArrowForward } from '@mui/icons-material';
import axios from 'axios';
import config from '../config.js';

export default function CarouselElement() {
    const theme = useTheme();

    const [selectedImage, setSelectedImage] = useState(null);
    const [visibleRange, setVisibleRange] = useState([0, 4]); // Show first images by default
    const [photos, setPhotos] = useState([]);

    const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
    const api = axios.create({  // Set the base URL for Axios requests
        baseURL: config[environment].apiUrl.replace('/api', ''),
    });

    useEffect(() => {
        const fetchPhotos = async () => {
            api.get('/api/getPromotionImages')
                .then((response) => {
                    //console.log("Photos:", response.data);
                    setPhotos(response.data);
                    if (response.data?.length > 0) {
                        setSelectedImage(response.data[0]);
                    }
                })
                .catch((error) => {
                    console.log("Get Promotion Photos:", error);
                })
        };

        fetchPhotos();
    }, []);

    // Handler for navigating up in the thumbnail list
    const handleScrollUp = () => {
        setVisibleRange(([start, end]) => [Math.max(0, start - 1), Math.max(3, end - 1)]);
    };

    // Handler for navigating down in the thumbnail list
    const handleScrollDown = () => {
        setVisibleRange(([start, end]) => [start + 1, end + 1]);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%', // Full viewport height
                backgroundColor: "#171717",
                overflow: 'hidden',
            }}
        >
            <Grid container spacing={3} sx={{ alignItems: 'center', }}>

                {/* Main selected image in the center, vertically aligned */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <Box
                        component="img"
                        src={selectedImage}
                        //alt={product.title}
                        sx={{
                            width: '50%',
                            height: '100%',
                            //maxHeight: '50vh', // Limit the maximum height of the image
                            //borderRadius: '12px',
                            //boxShadow: 5,
                            objectFit: 'cover',
                        }}
                    />
                </Grid>

              
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: "center" }}>
                        <IconButton onClick={handleScrollUp} disabled={visibleRange[0] === 0}>
                            <ArrowBack sx={{color: 'white'}} />
                        </IconButton>
                        {photos?.slice(visibleRange[0], visibleRange[1]).map((image, index) => (
                            <Box
                                key={index}
                                component="img"
                                src={image}
                                //alt={product.title}
                                sx={{
                                    width: '15vw',
                                    height: '15vh',
                                    cursor: 'pointer',
                                    borderRadius: '15px',
                                    border: selectedImage === image ? `3px solid ${theme.palette.primary.main}` : '2px solid transparent',
                                    boxShadow: selectedImage === image ? 5 : 0,
                                    mx: 1,
                                    objectFit: 'cover',
                                }}
                                onClick={() => setSelectedImage(image)}
                            />
                        ))}
                        <IconButton onClick={handleScrollDown} disabled={visibleRange[1] >= photos?.length}>
                            <ArrowForward sx={{color: 'white'}}/>
                        </IconButton>
                    </Box>
                </Grid>


            </Grid>
        </Box>
    );
}
