import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Container, Grid, Typography, TextField, Button, Alert, Snackbar } from '@mui/material';

import axios from 'axios';
import config from '../config.js';
import { set } from 'date-fns';
const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
const api = axios.create({  // Set the base URL for Axios requests
  baseURL: config[environment].apiUrl.replace('/api', ''),
});

const ProductHeroLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minHeight: '50vh',
  [theme.breakpoints.up('sm')]: {
    height: '50vh',
    //minHeight: '100px',
    //maxHeight: 1300,
  },
}));

const Background = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: -2,
});

function ProductHeroLayout(props) {
  const { sxBackground, children } = props;
  const [email, setEmail] = useState('');
  const [existingEmails, setExistingEmails] = useState([]);

  const [alertOn, setAlertOn] = useState(false);
  const [alertOnEmailExist, setAlertOnEmailExists] = useState(false);
  const [alertOnEmailFail, setAlertOnEmailFail] = useState(false);

  const [gridSizeSX, setGridSizeSX] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    // Define your custom breakpoints here
    if (width < 600) {
      setGridSizeSX(true);
    }
  }, []); // Fetch existing data when the component mounts

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const alertClose = () => {
    setAlertOn(false);
    setAlertOnEmailExists(false);
    setAlertOnEmailFail(false);
  }

  const handleSubscribe = async () => {
    if (!email) {
      setAlertOnEmailFail(true);
      return;
    }

    const check = await checkEmail(email);
    console.log('Check:', check);

    if (check) {
      setAlertOnEmailExists(true);
      return;
    } else {
      addEmail(email);  // Add the email to the database
      setEmail('');
      setAlertOn(true);
    }   
    
  };

  const checkEmail = async (email) => {
    try {
      const response = await api.post('/api/checkEmail', {
        email: email, // The email to check
      });
  
      if (response.data.exists) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error checking email:', error.response ? error.response.data : error.message);
    }
  };

  const addEmail = async (email) => {
    try {
      const response = await api.post('/api/addEmail', {
        email: email, 
      });
    } catch (error) {
      console.error('Error adding email:', error.response ? error.response.data : error.message);
    }
  };
  

  return (
    <ProductHeroLayoutRoot>
      <Container
        sx={{
          mt: 1,
          mb: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'common.black',
            opacity: 0.3, //Background Opacity
            zIndex: -1,
          }}
        />
        <Background sx={sxBackground} />
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
            backgroundColor: 'rgba(15, 15, 15, 1)',
            borderRadius: '10px',
            padding: '20px',
            textAlign: 'center',
            width: gridSizeSX ? '80%' : '50%',
          }}
        >
          <Grid container >
            <Grid item xs={12} >
              <div
                style={{
                  backgroundPosition: 'center',
                  padding: '20px',
                  textAlign: 'center',
                  borderRadius: '10px',

                }}
              >
                <Typography variant="h4" gutterBottom sx={{ color: 'white', fontSize: { xs: "1.5rem", mb: "2rem" } }}>
                  Subscribe to Our Newsletter
                </Typography>
                <Typography variant="body2" paragraph>
                  Stay updated with our latest news.
                </Typography>
                <form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center', // Center horizontally
                      marginBottom: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Enter your email"
                      fullWidth
                      sx={{ width: '100%', backgroundColor: 'white', marginRight: '10px' }}
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{ backgroundColor: 'blue' }}
                      onClick={handleSubscribe}
                    >
                      Subscribe
                    </Button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>



        </Box>
        <Snackbar
          open={alertOn}
          autoHideDuration={4000}
          onClose={alertClose}
          anchorOrigin={
            { vertical: 'bottom', horizontal: 'center' }
          }
        >
          <Alert
            onClose={alertClose}
            sx={{ width: '100%' }}
            severity='success'
            variant='filled'
          >
            Email Added to Newsletter
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertOnEmailExist}
          autoHideDuration={4000}
          onClose={alertClose}
          anchorOrigin={
            { vertical: 'bottom', horizontal: 'center' }
          }
        >
          <Alert
            onClose={alertClose}
            sx={{ width: '100%' }}
            severity='error'
            variant='filled'
          >
            Email Already is Subscribed
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertOnEmailFail}
          autoHideDuration={4000}
          onClose={alertClose}
          anchorOrigin={
            { vertical: 'bottom', horizontal: 'center' }
          }
        >
          <Alert
            onClose={alertClose}
            sx={{ width: '100%' }}
            severity='error'
            variant='filled'
          >
            Email Not Added to Newsletter
          </Alert>
        </Snackbar>
      </Container>
    </ProductHeroLayoutRoot>
  );
}

ProductHeroLayout.propTypes = {
  children: PropTypes.node,
  sxBackground: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default ProductHeroLayout;
