import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, LoadScript, useLoadScript } from "@react-google-maps/api";
import { MarkerF } from '@react-google-maps/api'

import { Skeleton } from '@mui/material';

import axios from 'axios';
import config from '../config.js';
const environment = process.env.NODE_ENV || 'development';
const api = axios.create({
  baseURL: config[environment].apiUrl.replace('/api', ''),
});

function PropertyMap({ property }) {
  const [mapKey, setMapKey] = useState("");
  const [loading, setLoading] = useState(true);
  const mapContainerStyle = {
    width: '100%',
    height: '75vh',
  };

  useEffect(() => {
    // Fetch Google Map API key
    api.get('/api/getGoogleMapKey')
      .then(response => {
        setMapKey(response.data.apiKey); // Set the API key from the response data
      })
      .catch(error => {
        console.error('Error fetching API key:', error);
        setLoading(false)
      });
  }, []);

  // Render the map or an error message
  return (
    <div>
      {!loading ? (
        <Skeleton variant="rectangular" width="100%" height="60vh" />
      ) : (
        mapKey ? ( // Only render LoadScript if mapKey is available
          <LoadScript async googleMapsApiKey={mapKey}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={{ lat: 39.341783622076086, lng: -76.66618875765418 }}
              zoom={17}
            >
              <MarkerF position={{ lat: 39.341783622076086, lng: -76.66618875765418 }} />
            </GoogleMap>
          </LoadScript>
        ) : (
          <Skeleton variant="rectangular" width="100%" height="60vh" />
        )
      )}
    </div>
  );
}

export default PropertyMap;
